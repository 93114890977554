import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';

const { gutter } = constants;

export const Login = styled.div(() => {
	return css({
		width: '100vw',
		minHeight: '100vh',
		padding: gutter * 3,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	});
});

export const LoginInner = styled.div(() => {
	return css({
		width: 500,
		maxWidth: '100%',
	});
});

export const CardInner = styled.div(() => {
	return css({ padding: gutter * 3 });
});

export const FormInner = styled.div(() => {
	return css({
		display: 'flex',
		flexDirection: 'column',
		gap: gutter * 2,
	});
});

export const LoginOrRegisterWrap = styled.div(() => {
	return css({
		display: 'flex',
		gap: gutter * 2,
		alignItems: 'center',
	});
});
