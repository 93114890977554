import { typography } from '@kinsta/base-style';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Button, Card, CardHeader, Form, Head, Input } from '@/src/components';
import { useLocaleTranslations } from '@/src/hooks';
import useForm from '@/src/hooks/useForm';
import * as Styled from '@/src/pages/login/login.style';
import { LoginUser, Role } from '@/src/utilities/graphql-types.gen';

import { useLoginMutation } from './login.queries.gen';

const { Anchor } = typography;

const Login = () => {
	const form = useForm();
	const [login] = useLoginMutation();
	const router = useRouter();

	const { isSubmitting } = form.formState;

	const handleSubmit = async (user: LoginUser) => {
		const redirect =
			router.query.to !== '/' ? (router.query.to as string) : null;
		await login({ variables: { user } }).then(({ data, errors }) => {
			if (errors) {
				return;
			}

			const sendTo = {
				[Role.SUPERADMIN]: '/admin',
				[Role.ADMIN_AFFILIATE]: '/admin',
				[Role.AFFILIATE]: '/',
				[Role.SELLER]: '/sales/sellers',
				[Role.ADMIN_SELLER]: '/sales/sellers',
				[Role.MK_REFERRER]: '/login',
			};
			router.push(redirect ?? sendTo[data?.login?.role ?? Role.AFFILIATE]);
		});
	};

	const translations = useLocaleTranslations();
	const errorTranslations = useLocaleTranslations().errorTranslations;

	return (
		<>
			<Head title={translations.login} />
			<Styled.Login>
				<Styled.LoginInner>
					<Card>
						<CardHeader hasBorderBottom>{translations.login}</CardHeader>
						<Styled.CardInner>
							<Form form={form} onSubmit={handleSubmit}>
								<Styled.FormInner>
									<Input
										type='email'
										name='email'
										label={translations.email}
										errorTranslations={errorTranslations}
									/>
									<Input
										type='password'
										name='password'
										label={translations.password}
										errorTranslations={errorTranslations}
									/>

									<Styled.LoginOrRegisterWrap>
										<Button
											style={{ alignSelf: 'flex-start' }}
											isLoading={isSubmitting}
										>
											{translations.login}
										</Button>
										<Link href='/register' passHref legacyBehavior>
											<Anchor>{translations.orRegister}</Anchor>
										</Link>
										<Link href='/forgot-password' passHref legacyBehavior>
											<Anchor style={{ marginLeft: 'auto' }}>
												{translations.forgotPassword}
											</Anchor>
										</Link>
									</Styled.LoginOrRegisterWrap>
								</Styled.FormInner>
							</Form>
						</Styled.CardInner>
					</Card>
				</Styled.LoginInner>
			</Styled.Login>
		</>
	);
};

export default Login;
