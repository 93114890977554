/* eslint-disable */
import * as Types from '../../utilities/graphql-types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/src/hooks/overriddenApolloClientHooks';
const defaultOptions = {} as const;
export type LoginMutationVariables = Types.Exact<{
  user: Types.LoginUser;
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: { __typename?: 'Affiliate', id: string, role: Types.Role, email: string } | { __typename?: 'BareUser', id: string, role: Types.Role, email?: string | null } | { __typename?: 'Seller', id: string, role: Types.Role, email: string } | null };


export const LoginDocument = gql`
    mutation Login($user: LoginUser!) {
  login(user: $user) {
    id
    role
    email
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;